import styled from "styled-components";
import { breakpoints } from "../../constants/media";

const Container = styled.header`
  width: 100% !important;
  margin: 0 !important;
  position: fixed !important;
  top: 0 !important;
  border-bottom: 1px solid var(--grisClaro);
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: saturate(180%) blur(20px);
  z-index: 92 !important;

  // Logo y Nav Container
  #logo-nav-container {
    width: calc(100% - 80px);
    max-width: 1270px;
    height: 64px;
    margin: 0 auto;
   // padding: 0px 40px 0px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  // GyS Logo Botón
  a {
    height: 26.7px;
  }

  // Logo
  a img {
    width: 195px;
    transition: 0.2s all;
    cursor: pointer;
  }

  // Logo Hover
  a:hover > img {
    transform: scale(0.95);
  }

  // Nav
  nav {
    height: 64px;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
  }

  // Nav Lista
  nav ul {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    list-style-type: none;
  }

  // Nav Lista Items
  nav ul li {
    margin: 0px 16px 0px 0px;
  }

  // Nav Lista Items Botones
  nav ul li a {
    width: 100%;
    font-family: "DM Sans", sans-serif;
    font-size: 13px !important;
    font-weight: 500;
    color: var(--gris);
    text-decoration: none;
    transition: 0.2s all;
    cursor: pointer;
  }

  // Nav Lista Items Botones Hover
  nav ul li a:hover {
    color: var(--negro);
  }

  // Botón Activo
  .boton-activo {
    color: var(--verde50) !important;
    font-weight: 600 !important;
  }

  // Item Lista PAS
  #pas {
    margin: 0px 10px 0px 0px;
  }

  // Botón PAS
  #pas a {
    width: 100%;
    padding: 4px 8px 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: var(--azul60);
    border-radius: 8px;
  }

  // Texto Botón PAS
  #pas a p {
    margin: 0px 0px 0px 0px;
    font-style: normal;
    font-size: 13px !important;
    font-weight: 500;
    color: var(--blanco);
  }

  // Ícono Botón PAS
  #pas a span {
    margin: 4px 8px 0px 0px;
    font-size: 18px !important;
    color: var(--blanco);
  }

  // Botón PAS Hover
  #pas a:hover {
    background-color: var(--azul0);
  }

  // 1600px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1600}) {
    // Logo y Nav Container
    #logo-nav-container {
      max-width: 1150px;
    }
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    // Logo y Nav Container
    /*
    #logo-nav-container {
      max-width: 1000px;
    }
    */
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Logo y Nav Container
    #logo-nav-container {
      max-width: 800px;
    }

    // Botón PAS
    #pas a {
      padding: 4px 6px 4px 6px;
    }

    // Texto Botón PAS
    #pas a p {
      display: none;
    }

    // Ícono Botón PAS
    #pas a span {
      margin: 4px 0px 0px 0px;
    }
  }

  // 1024px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
    // Logo y Nav Container
    #logo-nav-container {
      justify-content: center;
    }

    // Nav Desktop
    .navDesktop {
      display: none;
    }
  }
`;

export default Container;
