import Container from "./styled";
import Pregunta from "./pregunta";
import { Fade } from "react-awesome-reveal";
import { useQuestion } from "../../../hooks/useQuestion";
import { FAQS } from "../../../constants/faqs";

export default function Faqs() {
  const { isQuestionOpen, handleToggle } = useQuestion();

  return (
    <Container>
      <html itemscope itemtype="https://www.segurobici.com.ar/FAQPage"></html>
      <div id="max-width-container">
        {/* Titulo */}
        <Fade className="fade-container-tiulo" delay={50} duration={700}>
          <h3>
            <span>Preguntas</span> más frecuentes
          </h3>
        </Fade>
        {/* Preguntas Container */}
        <div id="preguntas-container">
          {FAQS.map((faq, index) => {
            return (
              <Fade
                key={faq.id}
                delay={100}
                duration={600}
                className={
                  isQuestionOpen === index
                    ? "pregunta-container-abierta"
                    : "pregunta-container"
                }
              >
                <Pregunta
                  title={faq.pregunta}
                  clickFunction={() => handleToggle(index)}
                  isActive={isQuestionOpen === index}
                >
                  {faq.respuesta}
                </Pregunta>
              </Fade>
            );
          })}
        </div>
      </div>
    </Container>
  );
}
