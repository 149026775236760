import React from "react";
import { Fade } from "react-awesome-reveal";
import Container from "./styled";

export default function ServicioCard({ servicio }) {
  return (
    <Container>
      <Fade
        className="container"
        delay={100}
        duration={800}
        key={servicio.titulo}
      >
          <img alt={servicio.alt} src={servicio.imagen}></img>
          <h3>{servicio.titulo}</h3>
          <p>{servicio.descripcion}</p>
      </Fade>
    </Container>
  );
}
