import Container from "./styled"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import HeaderSection from "../../components/HeaderSection";
import { useScrollTop } from "../../hooks/useScrollTop";
import Valores from "../../components/Decalogo/Valores";
import Principios from "../../components/Decalogo/Principios";

const Decalogo = () => {

  useScrollTop()
  return (
    <Container>
      <Header active="decalogo"></Header>
      <HeaderSection title={"Decálogo"} description={"En nuestra filosofía empresaria, se destacan valores y principios que le dan razón de ser a la empresa y generan un alto compromiso con el productor asesor."} />
      <Valores />
      <Principios /> 
      <Footer />
    </Container>
  );
}

export default Decalogo