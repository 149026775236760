import React from "react";
import { Fade } from "react-awesome-reveal";
import Container from "./styled";

export default function Valores() {
  return (
    <Container>
      {/* Max Width Container */}
      <div id="max-width-container">
        {/* Titulo */}
        <Fade className="fade-container-titulo" delay={50} duration={700}>
          <h3>
            Nuestros <span>valores</span>
          </h3>
        </Fade>
        {/* Cards Container */}
        <div>
          <Fade className="fade-container" delay={200} duration={700}>
            {/* Card 1 */}
            <div className="shimmer">
              {/* Title */}
              <h3>Compromiso</h3>
            </div>
          </Fade>
          <Fade className="fade-container" delay={200} duration={700}>
            {/* Card 2 */}
            <div className="shimmer">
              {/* Title */}
              <h3>Conocimiento</h3>
            </div>
          </Fade>
          <Fade className="fade-container" delay={200} duration={700}>
            {/* Card 3 */}
            <div className="shimmer">
              {/* Title */}
              <h3>Capacitación</h3>
            </div>
          </Fade>
          <Fade className="fade-container" delay={200} duration={700}>
            {/* Card 4 */}
            <div className="shimmer">
              {/* Title */}
              <h3>Dinamismo</h3>
            </div>
          </Fade>
          <Fade className="fade-container" delay={200} duration={700}>
            {/* Card 5 */}
            <div className="shimmer">
              {/* Title */}
              <h3>Responsabilidad</h3>
            </div>
          </Fade>
          <Fade className="fade-container" delay={200} duration={700}>
            {/* Card 6 */}
            <div className="shimmer">
              {/* Title */}
              <h3>Servir</h3>
            </div>
          </Fade>
          <Fade className="fade-container" delay={200} duration={700}>
            {/* Card 7 */}
            <div className="shimmer">
              {/* Title */}
              <h3>Transparencia</h3>
            </div>
          </Fade>
        </div>
      </div>
    </Container>
  );
}
