import React from "react";
import Container from "./styled";
import { Fade } from "react-awesome-reveal";

export default function Principios() {
  return (
    <Container>
      {/* Max Width Container */}
      <div id="max-width-container">
        {/* Titulo */}
        <Fade className="fade-container-titulo" delay={50} duration={700}>
          <h3>
            Nuestros <span>10 principios</span>
          </h3>
        </Fade>
        <div className="grid-container">
          {/* Columna Izquierda */}
          {/* Item 1 */}
          <Fade
            delay={100}
            duration={800}
            className="fade-container border-right"
          >
            <div>
              <span>1</span>
              <p>
                Nuestro objetivo es que el Productor Asesor de Seguros{" "}
                <strong>viva una experiencia</strong> de calidad.
              </p>
            </div>
          </Fade>
          {/* Item 2 */}
          <Fade
            delay={100}
            duration={800}
            className="fade-container border-right"
          >
            <div>
              <span>2</span>

              <p>
                Acompañar al Productor Asesor de Seguros para que{" "}
                <strong>obtenga nuevos negocios y genere crecimiento</strong> en
                la cartera existente.
              </p>
            </div>
          </Fade>
          {/* Item 3 */}
          <Fade
            delay={100}
            duration={800}
            className="fade-container border-right"
          >
            <div>
              <span>3</span>

              <p>
                Concederle al Productor Asesor de Seguros una{" "}
                <strong>atención personalizada</strong> con el objeto de que
                nuestra labor le resulte de alto beneficio.
              </p>
            </div>
          </Fade>
          {/* Item 4 */}
          <Fade delay={100} duration={800} className="fade-container">
            <div>
              <span>4</span>

              <p>
                Mantener un respeto irrestricto con el propósito de ser{" "}
                <strong>eficientes</strong> en materia del resultado que Gestión
                y Servicios S.R.L. le genera a las aseguradoras.
              </p>
            </div>
          </Fade>
          {/* Item 5 */}
          <Fade
            delay={100}
            duration={800}
            className="fade-container span-2 border-top border-right"
          >
            <div>
              <span>5</span>

              <p>
                <strong>Valores que consideramos fundamentales</strong> para un
                correcto desempeño de las tareas:
                <ul>
                  <li>Actitud proactiva.</li>
                  <li>
                    Tener incorporado el concepto de permanente crecimiento.
                  </li>
                  <li>Accionar con creatividad.</li>
                  <li>Trabajar con el concepto de diferenciación.</li>
                  <li>Asumir responsabilidades.</li>
                </ul>
              </p>
            </div>
          </Fade>
          {/* Item 6 */}
          <Fade
            delay={100}
            duration={800}
            className="fade-container border-top border-right"
          >
            <div>
              <span>6</span>

              <p>
                Crear un <strong>ámbito de confianza</strong> plena hacia el
                Productor Asesor de Seguros, para profundizar al máximo la
                relación de personas que pregona la actividad aseguradora.
              </p>
            </div>
          </Fade>
          {/* Columna Derecha */}
          {/* Item 7 */}
          <Fade
            delay={100}
            duration={800}
            className="fade-container border-top"
          >
            <div>
              <span>7</span>

              <p>
                Ser <strong>pacientes, dedicados y apasionados</strong>: hacemos
                lo que nos gusta hacer.
              </p>
            </div>
          </Fade>
          {/* Item 8 */}
          <Fade
            delay={100}
            duration={800}
            className="fade-container border-top border-right"
          >
            <div>
              <span>8</span>

              <p>
                Valoramos la <strong>vocación de servir</strong>.
              </p>
            </div>
          </Fade>
          {/* Item 9 */}
          <Fade
            delay={100}
            duration={800}
            className="fade-container span-2 border-top border-right"
          >
            <div>
              <span>9</span>

              <p>
                Tener siempre presente, en cada una de nuestras acciones, el{" "}
                <strong>concepto de rentabilidad</strong>:
                <ul>
                  <li>
                    Rentabilidad para el asegurado: brindarle al Productor
                    Asesor de Seguros los mejores precios.
                  </li>
                  <li>
                    Rentabilidad para el Productor Asesor de Seguros: a través
                    de interesantes comisiones y condiciones de operatividad.
                  </li>
                  <li>
                    Rentabilidad para la Cía. de Seguros: en función del aporte
                    de una cartera cobrable, de equilibrada siniestralidad y en
                    permanente crecimiento.
                  </li>
                  <li>
                    Autonomía: el Productor Asesor de Seguros tendrá total
                    independencia en el manejo de cotizaciones por medio del uso
                    de formularios de cotización a su disposición.
                  </li>
                </ul>
              </p>
            </div>
          </Fade>
          {/* Item 10 */}
          <Fade
            delay={100}
            duration={800}
            className="fade-container border-top"
          >
            <div>
              <span>10</span>
              <p>
                <strong>Docentes</strong> para transmitir,{" "}
                <strong>humildad</strong> para dar y vocación de{" "}
                <strong>aprender</strong>.
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </Container>
  );
}
