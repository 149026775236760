import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.nav`
  height: 100vh !important;
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--blanco);
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  z-index: 111 !important;

  // Botón Cerrar
  button {
    margin: 0px 0px 0px 38px !important;
    position: absolute;
    left: 0px;
    top: 13px;
    font-size: 31px;
    color: var(--negro);
    background-color: transparent;
    border: 0;
    transition: 0.2s all;
    cursor: pointer;
  }

  // Botón Cerrar Hover
  button:hover {
    color: var(--azul100);
  }

  // Botones Container
  #botonesContainer {
    width: auto;
    height: 100vh;
    padding: 0px 0px 0px 0px !important;
    margin: 65px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    border-right: 1px var(--grisClaro) solid;
  }

  // Linea
  hr {
    width: 100%;
    margin: 0px 0px 0px 0px;
    border-color: var(--grisClaro) 1px solid;
    opacity: 0.15;
  }

  // Nav Lista Items
  li {
    width: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    list-style-type: none;
  }

  // Nav Lista Items Botones
  li a {
    padding: 16px 48px 16px 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 13px !important;
    font-weight: 500;
    color: var(--gris);
    text-decoration: none;
    transition: 0.2s all;
    cursor: pointer;
  }

  // Nav Lista Items Botones Hover
  li:hover > a {
    color: var(--blue);
  }

  // Botón PAS
  #pas a {
    width: auto !important;
    padding: 4px 8px 4px 8px;
    margin: 16px 48px 0px 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 12px !important;
    color: var(--blanco);
    background-color: var(--azul40);
    border-radius: 8px;
  }

  // Ícono Botón PAS
  #pas a span {
    margin: 4px 8px 0px 0px !important;
    font-size: 18px !important;
  }

  // Botón PAS Hover
  #pas a:hover {
    background-color: var(--azul0);
  }

  // 1024px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
    display: flex;
  }
`;

export default Container;
