import { useForm } from "../../hooks/useForm";
import { contactoReglas } from "../../utils/formRules";
import { contactoForm } from "../../utils/formInitialValues";
import InnerContainer from "../InnerContainer";
import ContactForm from "../Forms/ContactForm";
import Container from "./styled";
import { BsWhatsapp } from "react-icons/bs";
import { SiMaildotru } from "react-icons/si";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { HiOutlineLocationMarker } from "react-icons/hi";
import axios from "axios";
import url from "../../utils/url";
import { useState } from "react";
import { Fade } from "react-awesome-reveal";

const Contacto = () => {
  // Custom Hooks
  const formValues = useForm(handleSubmit, contactoReglas, contactoForm);
  const [buttonText, setButtonText] = useState("Enviar")

  async function handleSubmit() {
    setButtonText("Enviando...")
    try {
      const content = `
      <html><body>
      <h2>Contacto</h2>
        <p>Nombre: ${formValues.inputValues.nombre} ${formValues.inputValues.apellido}</p>
        <p>Email: ${formValues.inputValues.email}</p>
        <p>Telefono: ${formValues.inputValues.telefono}</p>
        <p>PAS: ${formValues.inputValues.pas ? "Sí" : "No"}</p>
        <p>Mensaje: ${formValues.inputValues.mensaje}</p>
      </body></html>
    `;
    const data = {
      emails: ["rodriandrade1498@gmail.com"],
      subject: `Consulta de productor - ${formValues.inputValues.nombre} ${formValues.inputValues.apellido}`,
      htmlContent: content,
    };
      const send = await axios.post(`${url}/api/enviarMail`, data);
      if(send.status === 200) {
        setButtonText("¡Mensaje enviado!")
        formValues.resetForm()
        setTimeout(() => {
          setButtonText("Enviar")
        }, 2000)
      }
    } catch (error) {
      console.log(error)
      setButtonText("Enviar")
    }
  }

  return (
    <Container>
      <InnerContainer>
        <h3 className="tituloMobile">Comunicate con <span>nosotros</span></h3>
        <div className="container-descripcion">
          <h3 className="titulo">Comunicate con <span>nosotros</span></h3>
          <p className="descripcion">
            Completá el siguiente formulario y envianos tu consulta, pronto nos
            comunicaremos con vos. También podes comunicarte por WhatsApp,
            correo electrónico o por nuestras redes.
          </p>
          <div className="medios-container">
            <h4>Medios de contacto</h4>
            <div className="medios">
              <a href="mailto:info@gyssrl.com.ar">
                <span>
                  <SiMaildotru />
                </span>
                info@gyssrl.com.ar
              </a>
              <a
                className="whatsapp"
                href="https://api.whatsapp.com/send?phone=541138043972&text=%C2%A1Hola%2C%20me%20gustar%C3%ADa%20obtener%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Gesti%C3%B3n%20y%20Servicios!"
                target="_blank"
                rel="nofollow"
              >
                <span className="whatsapp">
                  <BsWhatsapp />
                </span>
                11 3804-3972
              </a>
            </div>
          </div>
          <div>
            <h4>Nuestras redes</h4>
            <div className="social-media-container">
              {/* Facebook Button */}
              <a
                href="https://www.facebook.com/gyssrl.broker/"
                target="_blank"
                rel="nofollow"
              >
                <FaFacebookF />
              </a>
              {/* Instagram Button */}
              <a
                href="https://www.instagram.com/gyssrl/"
                target="_blank"
                rel="nofollow"
              >
                <BsInstagram />
              </a>
              {/* LinkedIn Button */}
              <a
                href="https://www.linkedin.com/company/gyssrl/"
                target="_blank"
                rel="nofollow"
              >
                <FaLinkedinIn />
              </a>
            </div>
          </div>
          <div className="direccion-container">
            <h4>Nos podes encontrar en:</h4>
            <span>
              <span>
                <HiOutlineLocationMarker></HiOutlineLocationMarker>
              </span>
              Suipacha 245 1°, CABA.
            </span>
          </div>
        </div>
        <div className="container-form">
          <Fade
            delay={100}
            duration={800}
          >
          <ContactForm formValues={formValues} buttonText={buttonText} />
          </Fade>
        </div>
      </InnerContainer>
    </Container>
  );
};

export default Contacto;
