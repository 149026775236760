import Container from "./styled"
import Header from "../../components/Header"
import QuienesSomos from "../../components/home/quienesSomos";
import { useScrollTop } from "../../hooks/useScrollTop";
import SectionHome from "../../components/SectionHome";
import Contacto from "../../components/Contacto";
import Banner from "../../components/home/banner";
import Logos from "../../components/home/logos";
import ServiciosC from "../../components/ServiciosC";
import Faqs from "../../components/home/faqs";
import Footer from "../../components/Footer";

const Servicios = () => {
  useScrollTop()
  return (
    <Container>
      <Header active="inicio"></Header>
      <Banner />
      <QuienesSomos />
      <Logos />
      <SectionHome>
        <ServiciosC />
      </SectionHome>
      <SectionHome>
        <Contacto />
      </SectionHome>
      <Faqs />
      <Footer />
    </Container>
  );
}

export default Servicios