import styled from "styled-components";
import { breakpoints } from "../../constants/media";

const Container = styled.div`
  width: 100%;
  //margin: 368px 0px 0px 0px;
  background-color: var(--blanco);
  z-index: 1;

  // Max Width Container
  div {
    max-width: 1270px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Cards Containers
  div div {
    width: 100%;
    margin: 80px 0px 180px 0px !important;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 56px;
    grid-auto-rows: minmax(auto, auto);
    
  }

  // Cards
  div div div {
    max-height: 144px;
    min-height: 144px;
    margin: 0px 0px 0px 0px !important;
    display: flex;
    justify-content: center;
  }

  // Cards Container
  div div div a {
    width: 100%;
    height: 100%;
    min-height: 103px;
    padding-top: 24px;
    display: flex;
    justify-content: center;
    align-items: start;
    align-content: start;
    flex-wrap: wrap;
    background-color: var(--blanco);
    border-radius: 12px;
    border: 1px solid var(--grisClaro);
    //box-shadow: 0px 5px 30px var(--sombraAzul);
    text-decoration: none;
    position: relative;
    cursor: pointer;
  }

  // Cards Image
  img {
    width: 65%;
    min-height: 42px;
    max-height: 42px;
    margin: 10px 0px 0px 0px;
    display: block !important;
    object-fit: contain;
    transition: 0.2s all;
  }

  // Logos
  #federacionLogo {
    width: 75%;
  }

  #sancorLogo,
  #colonLogo {
    min-height: 32px;
    max-height: 32px;
    margin: 24px 0px 0px 0px;
  }

  #atmLogo,
  #libraLogo,
  #paranaTitle {
    min-height: 48px;
    max-height: 48px;
  }

  #victoriaLogo {
    min-height: 48px;
    max-height: 48px;
  }

  #victoriaTitle {
    margin: 24px 0px 0px 0px;
  }

  #atmTitle,
  #paranaTitle {
    margin: 24px 0px 0px 0px;
  }

  #libraTitle {
    margin: 36px 0px 0px 0px;
  }

  // Cards Title
  .title {
    width: 100%;
    margin: 32px 0px 0px 0px;
    text-align: center !important;
    color: var(--negro);
    text-align: center;
    font-size: 15px !important;
    font-weight: 500;
    border-top: 1px solid var(--grisClaro);
    display: flex;
    justify-content: center;
    padding: 16px 0;
  }

  // Cards Container Hover Image
  div div div a:hover > img {
    transform: scale(1.1);
  }

  // 1600px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1600}) {
    // Max Width Container
    div {
      max-width: 1150px;
    }
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    // Max Width Container
    div {
      max-width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    div {
      max-width: 800px;
    }

    // Cards Containers
    div div {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  // 1024px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
    // Max Width Container
    div {
      max-width: 600px;
    }

    // Cards Containers
    div div {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  // 768px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet768}) {
    // Max Width Container
    div {
      max-width: 540px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    div {
      max-width: 400px;
    }

    // Cards Containers
    div div {
      grid-template-columns: repeat(1, 1fr);
      justify-content: space-evenly;
      justify-items: center;
      align-content: space-evenly;
      align-items: center;
    }

    // Cards
    div div div {
      width: 90%;
    }
  }

  // 450px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile450}) {
    // Max Width Container
    div {
      max-width: 88%;
    }

    // Cards
    div div div {
      width: 100%;
    }
  }
`;

export default Container;
