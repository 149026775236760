import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  padding: 72px 0px 72px 0px;
  background-color: var(--blanco);
  border-top: 1px var(--grisClaro) solid;
  border-bottom: 1px var(--grisClaro) solid;
  z-index: 1;

  // Max Width Container
  div {
    max-width: 1270px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    align-content: center;
  }

  // Fade Container
  .fade-container-texto {
    width: 100%;
    max-width: 600px;
    margin: 0px 0px 0px 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
  }

  // Titulo Texto Container
  div div div {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    align-content: flex-end;
  }

  // Titulo y Texto
  div div h3,
  div div p {
    width: 100%;
    text-align: left;
  }

  // Titulo
  div div h3 {
    margin: 0px 0px 40px 0px;
    color: var(--azul60);
    font-weight: 500;

    span {
      color: var(--negro);
    }
  }

  ul {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  ul li {
    font-size: 14.5px;
    color: var(--gris);
    line-height: 1.7;
    font-family: "DM Sans";
  }

  // Texto
  div div p {
    margin: 0px 0px 0px 0px;
    color: var(--negro);
    line-height: 28px;
  }

  // Animación Container
  .animacion-container {
    width: 100%;
    max-width: 480px;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  // 1600px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1600}) {
    // Max Width Container
    div {
      max-width: 1150px;
    }
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    // Max Width Container
    div {
      max-width: 1000px;
    }

    // Fade Container
    .fade-container-texto {
      max-width: 480px;
      margin: 0px 40px 0px 0px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    div {
      max-width: 800px;
    }

    // Fade Container
    .fade-container-texto {
      max-width: 310px;
      margin: 0px 30px 0px 0px;
    }

    // Animación Container
    .animacion-container {
      max-width: 460px;
    }
  }

  // 1024px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
    // Max Width Container
    div {
      max-width: 600px;
      justify-content: center;
    }

    // Fade Container
    .fade-container-texto {
      max-width: 100%;
      width: 100%;
      margin: 0px 0px 0px 0px;
      justify-content: center;
    }

    // Titulo Texto Container
    .fade-container-texto div {
      width: 100%;
      margin: 0px 0px 0px 0px;
      justify-content: center;
    }

    // Animación Container
    .animacion-container {
      margin: 32px 0px 0px 0px;
      justify-content: center;
    }
  }

  // 768px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet768}) {
    // Max Width Container
    div {
      max-width: 540px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    div {
      max-width: 400px;
    }
  }

  // 450px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile450}) {
    // Max Width Container
    div {
      max-width: 88%;
    }
  }
`;

export default Container;
