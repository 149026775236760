import Container from "./styled";
import { SERVICIOS } from "../../../constants/servicios";
import ServicioCard from "../../ServicioCard";

export default function ServiciosCards() {
  return (
    <Container>
      {/* Max Width Container */}
      <div id="max-width-container">
        {/* Cards Container */}
        <div id="cards-container">
          {SERVICIOS.map(servicio => <ServicioCard servicio={servicio}/> )}
        </div>
      </div>
    </Container>
  );
}
