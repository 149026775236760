import React, { useRef } from "react";
import { bool } from "prop-types";
import Container from "./styled";
import disableScroll from "disable-scroll";
import { IoClose } from "react-icons/io5";
import { MdOutlinePersonOutline } from "react-icons/md";
import { Link } from "react-router-dom";

export default function Menu({ open, active, setOpen, useOutsideAlerter }) {
  const toggleOpen = () => {
    setOpen(!open);
  };

  const referencia = useRef(null);
  useOutsideAlerter(referencia);

  return (
    <Container open={open} ref={referencia}>
      {/* Botón Cerrar */}
      <button onClick={toggleOpen}>
        <IoClose></IoClose>
      </button>
      {/* Botones Container */}
      <div id="botonesContainer">
        <hr />
        {/* Inicio */}
        <li>
          <Link className={active == "inicio" && "button_active"} to="/">
            Inicio
          </Link>
        </li>
        {/* Decálogo */}
        <hr />
        <li>
          <Link
            className={active == "decalogo" && "button_active"}
            to="/decalogo"
          >
            Decálogo
          </Link>
        </li>
        {/* Aseguradoras */}
        <hr />
        <li>
          <Link
            to="/aseguradoras"
            className={active == "aseguradoras" && "button_active"}
          >
            Aseguradoras
          </Link>
        </li>
        {/* Servicios */}
        <hr />
        <li>
          <Link
            className={active == "servicios" && "button_active"}
            to="/servicios"
          >
            Servicios
          </Link>
        </li>
        <hr />
        <li>
          <Link
            to="/sobre-nosotros"
            className={active == "sobre-nosotros" && "boton-activo"}
          >
            Sobre Nosotros
          </Link>
        </li>
        {/* Consultanos */}
        <hr />
        <li>
          <Link
            className={active == "consultanos" && "button_active"}
            to="/consultanos"
          >
            Consultanos
          </Link>
        </li>
        <hr />
        {/* Ingreso PAS */}
        <li id="pas">
          <Link to="https://gyssrl.com.ar/productores/index.php" target="_blank">
            <span>
              <MdOutlinePersonOutline></MdOutlinePersonOutline>
            </span>
            Ingreso PAS
          </Link>
        </li>
      </div>
    </Container>
  );
}
