import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  background-color: var(--blanco);
  z-index: 1;

  // Max Width Container
  #max-width-container {
    max-width: 1270px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Cards Containers
  #cards-container {
    width: 100%;
    margin-top: 64px;
    margin-bottom: 180px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    grid-auto-rows: minmax(auto, auto);
  }

  // Card Container
  .fade-container-card {
    //margin: 64px 0px 0px 0px;
    padding: 24px 24px 40px 24px;
    //box-shadow: 0px 5px 30px var(--sombraAzul);
    border-radius: 12px;
    border: 1px solid var(--grisClaro);
    width: 100%;
  }

  // Card Container
  .fade-container-card div {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Cards Title
  .fade-container-card div h3 {
    width: 100%;
    text-align: left;
    margin: 16px 0px 0px 0px;
    font-size: 18px !important;
    color: var(--verde50);
    font-weight: 500;
  }

  // Card Imagen
  .fade-container-card div img {
    width: 180px;
    pointer-events: none;
  }

  // Card Texto
  .fade-container-card div p {
    width: 100%;
    max-width: 270px;
    text-align: left;
    font-size: 14.5px !important;
    line-height: 30px;
    margin: 24px 0px 0px 0px;
    color: var(--gris);
  }

  // 1600px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1600}) {
    // Max Width Container
    #max-width-container {
      max-width: 1150px;
    }

    // Card Container
    .fade-container-card {
      max-width: calc(320px - 48px);
    }
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    // Max Width Container
    #max-width-container {
      max-width: 1000px;
    }

    // Card Container
    .fade-container-card {
      max-width: calc(300px - 48px);
      margin: 80px 0px 0px 0px;
      min-height: 454px;
    }

    // Cards Title
    .fade-container-card div h3 {
      height: 48px;
    }

    #cards-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    #max-width-container {
      max-width: 800px;
    }

    // Card Container
    .fade-container-card {
      width: calc(45% - 48px);
      max-width: none;
      min-height: 397px;
      margin: 60px 0px 0px 0px;
    }

    // Cards Title
    .fade-container-card div h3 {
      height: auto;
    }

    #cards-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  // 1024px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
    // Max Width Container
    #max-width-container {
      max-width: 600px;
    }

    // Titulo
    h3 {
      width: 80%;
    }

    // Card Container
    .fade-container-card {
      width: calc(44% - 24px);
      min-height: 448px;
      padding: 16px 16px 40px 16px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      align-content: flex-start;
    }

    // Cards Title
    .fade-container-card div h3 {
      height: 48px;
    }

    #cards-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  // 768px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet768}) {
    // Max Width Container
    div {
      max-width: 540px;
    }

    // Card Container
    .fade-container-card {
      width: calc(44% - 24px);
      min-height: 478px;
    }

    // Card Imagen
    .fade-container-card div img {
      width: 97%;
      max-height: none;
      min-height: none;
    }

    #cards-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  // 651px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet670}) {
    // Max Width Container
    div {
      max-width: 400px;
    }

    // Titulo
    h3 {
      width: 100%;
    }

    // Card Container
    .fade-container-card {
      width: calc(100% - 24px);
      height: auto;
    }
  }

  // 450px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile450}) {
    // Max Width Container
    div {
      max-width: 100%;
    }

        // Max Width Container
    #max-width-container {
      max-width: 88%;
    }

  }
`;

export default Container;
