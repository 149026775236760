import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100vw;
  height: 448px;
  margin: 64px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: fixed;
  overflow: hidden;
  background: var(--grisClaro);
  background-image: url("images/banner/obelisco.jpg");
  background-size: cover;
  background-position: 50% 35%;
  z-index: 0;

  // Gradiente Container
  #gradiente-container {
    width: 100%;
    height: 448px;
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(
      90deg,
      rgba(0, 31, 255, 0) 37%,
      rgba(0, 31, 255, 0.5539565826330536) 47%,
      rgba(0, 31, 255, 0.7539565826330536) 57%,
      rgba(0, 31, 255, 1) 100%
    );
    z-index: 91 !important;
    overflow: hidden;
  }

  // Data Container
  #data-container {
    width: 1270px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 0;
    right: 0;
    z-index: 98 !important;
  }

  // Titulo y Texto
  h2,
  p {
    color: var(--blanco);
    text-align: right;
  }

  // Titulo
  h2 {
    width: 100%;
    margin: 0px 0px 0px 0px;
  }

  // Texto
  p {
    width: 500px;
    margin: 24px 0px 0px calc(100% - 500px);
  }

  // Botón
  a {
    width: fit-content;
    padding: 8px 16px 8px 16px;
    margin: 32px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: var(--negro);
    text-decoration: none;
    background-color: var(--verde100);
    border-radius: 8px;
    transition: 0.2s all;
    cursor: pointer;
  }

  // Botón Ícono
  a span {
    margin: 0px 0px 0px 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 12px;
    transition: 0.2s all;
  }

  // Botón Hover
  a:hover > span {
    transform: translate(6px);
  }

  // 1600px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1600}) {
    // Data Container
    #data-container {
      width: 1150px;
    }
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    // Data Container
    #data-container {
      width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Gradiente Container
    #gradiente-container {
      background: linear-gradient(
        90deg,
        rgba(0, 31, 255, 0) 30%,
        rgba(0, 31, 255, 0.6539565826330536) 43%,
        rgba(0, 31, 255, 0.8539565826330536) 53%,
        rgba(0, 31, 255, 1) 100%
      );
    }

    // Data Container
    #data-container {
      width: 800px;
    }
  }

  // 1024px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
    background-position: right;

    // Gradiente Container
    #gradiente-container {
      background: rgba(0, 31, 255, 0.8539565826330536);
    }

    // Data Container
    #data-container {
      width: 100%;
      justify-content: center;
    }

    // Titulo y Texto
    h2,
    p {
      text-align: center;
    }

    // Texto
    p {
      margin: 24px 0px 0px 0px;
    }

    // Botón
    a {
      margin: 32px 200px 0px 200px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Data Container
    #data-container {
      width: 400px;
    }
  }

  // 450px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile450}) {
    // Data Container
    #data-container {
      max-width: 88%;
    }
  }
`;

export default Container;
