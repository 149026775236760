import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  padding: 0px 0px 220px 0px;
  display: flex;
  justify-content: center;
  background-color: var(--blanco);
  border-top: 1px solid var(--grisClaro);
  z-index: 91;

  // Max Width Container
  #max-width-container {
    width: 100%;
    max-width: 760px;
    margin: 80px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
  }

  // Titulo
  h3 {
    width: 100%;
    margin: 0px 0px 0px 0px;
    text-align: center;
    color: var(--negro);
    font-weight: 500;

    span {
      color: var(--azul60);
    }
  }

  // Preguntas Container
  #preguntas-container {
    width: 100%;
    margin: 40px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }

  // Pregunta Container
  .pregunta-container {
    background-color: var(--blanco);
    width: 100%;
    margin: 42px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    text-align: center;
    border: 2px solid var(--grisClaro);
    border-radius: 16px;
    transition: 0.2s all;
  }

  // Pregunta Container Abierta
  .pregunta-container-abierta {
    background-color: var(--blanco);
    width: 100%;
    margin: 42px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    text-align: center;
    border: 2px solid var(--azul20);
    border-radius: 16px;
    transition: 0.2s all;
  }

  // 1024px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
    // Max Width Container
    #max-width-container {
      max-width: 600px;
    }
  }

  // 768px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet768}) {
    // Max Width Container
    div {
      max-width: 540px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    #max-width-container {
      max-width: 400px;
    }
  }

  // 414px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile414}) {
    // Max Width Container
    #max-width-container {
      max-width: 88%;
    }
  }
`;

export default Container;
