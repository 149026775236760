import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 20px;
  }

  .grid-container-textarea {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    margin: 20px 0;
  }
  
  .checkbox-container {
    font-size: 13px;
    font-family: "DM Sans";
    color: var(--negro);
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 20px 0;
  }

   // 1600px Desktop ------------------------------------------------------------------------------------
   @media (max-width: ${breakpoints.desktop1600}) {
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    .grid-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
  }

  // 1024px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
    .grid-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  // 768px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet768}) {
    .grid-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  // 651px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet670}) {
    .grid-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  // 450px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile450}) {

  }
`;

export default Container;
