import styled from "styled-components";
import { breakpoints } from "../../constants/media";

const Container = styled.div`
  width: 100%;
  padding: 120px 0px 120px 0px;
  background-color: var(--blanco);
  border-top: 1px var(--grisClaro) solid;
  z-index: 1;

  // Max Width Container
  #maxWidthContainer {
    max-width: 1270px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .logo-container {
    background-color: var(--blanco);
    border: 1px solid var(--grisClaro);
    padding: 20px 30px;
    border-radius: 8px;
  }

  .marquee {
    gap: 20px !important;
  }

  // Titulo
  h3 {
    width: 100%;
    //margin: 0px 0px 72px 0px;
    text-align: center;
    color: var(--negro);
    line-height: 52px;
    font-weight: 500;

    span {
      color: var(--azul60);
    }
  }

  // 1600px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1600}) {
    // Max Width Container
    #maxWidthContainer {
      max-width: 1150px;
    }
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    // Max Width Container
    #maxWidthContainer {
      max-width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    #maxWidthContainer {
      max-width: 800px;
    }
  }

  // 1024px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
    // Max Width Container
    #maxWidthContainer {
      max-width: 600px;
    }
  }

  // 768px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet768}) {
    // Max Width Container
    #maxWidthContainer {
      max-width: 540px;
    }
  }

  // 670px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet670}) {
    // Max Width Container
    #maxWidthContainer {
      max-width: 100%;
    }

    // Titulo
    h3 {
      max-width: 300px;
    }
  }
`;

export default Container;
