import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.button`
  width: 35px;
  padding: 0px 0px 0px 0px !important;
  margin: 0px 0px 0px 0px !important;
  position: absolute;
  left: 14px;
  display: none;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: transparent;
  border: none;
  z-index: 110 !important;
  cursor: pointer;

  // Botón Abrir
  button {
    height: 32px;
    background-color: transparent;
    border: 0;
    color: var(--negro);
    font-size: 28px;
    cursor: pointer;
    transition: 0.2s all;
  }

  // Botón Abrir Hover
  button:hover {
    color: var(--azul100);
  }

  // 1024px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
    display: flex;
  }
`;

export default Container;
