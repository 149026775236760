import React from 'react'
import { SERVICIOS } from '../../constants/servicios'
import ServicioCard from '../ServicioCard'
import Container from './styled'
import InnerContainer from "../InnerContainer"
import { Link } from 'react-router-dom'
const ServiciosC = () => {
  return (
    <Container>
        <InnerContainer>
            <h3>Nuestros <span>servicios</span></h3>
            <div className='grid-container'>
              {SERVICIOS.filter((servicio, index) => index < 4).map(servicio => <ServicioCard servicio={servicio}/>)} 
            </div>
            <div className='boton-container'>
            <Link to="/servicios">Ver todos nuestros servicios</Link>
            </div>
        </InnerContainer>
    </Container>
  )
}

export default ServiciosC