export const FAQS = [
  {
    id: 1,
    pregunta: "¿Por qué me conviene trabajar con Gestión y Servicios S.R.L.?",
    respuesta: (
      <p itemprop="text">
        Gestión y Servicios S.R.L. es una Empresa que tiene por objeto aportarle
        al Productor Asesor de Seguros una experiencia de servicio que
        contribuyan a su crecimiento como profesional del seguro. Destinamos
        nuestras energías a brindarle un servicio especializado a más de 300
        productores. Gestión y Servicios S.R.L. ofrece beneficios que le
        permiten al P.A.S.:
        <br></br>
        <br></br>• Incrementar su cartera mediante el desarrollo de
        emprendimientos y múltiples opciones en materia de cotizaciones de
        Riesgos.
        <br></br>• Obtener una rentabilidad mejorada.
        <br></br>• Bajar notablemente los costos operativos, siendo nosotros los
        que nos comprometemos frente al desafío de que el Productor Asesor de
        Seguros tenga un crecimiento.
        <br></br>• Obtener un sólido apoyo de nuestra parte en Aseguradoras de
        primer nivel.
        <br></br>• Un nuevo enfoque de negocios en momentos de alta competencia
        y dinamismo determinan algunas de las conveniencias derivadas de operar
        con Gestión y Servicios S.R.L.
      </p>
    ),
  },
  {
    id: 2,
    pregunta: "¿Qué hace Gestión y Servicios S.R.L. para que yo crezca?",
    respuesta: (
      <p itemprop="text">
        Con el objetivo de que el Productor Asesor de Seguras crezca de
                manera profesional, Gestión y Servicios ofrece:
                <br></br>
                <br></br>• Capacitación permanente en herramientas digitales y
                convencionales.
                <br></br>• Por medio de nuestras más de 20 aseguradoras, obtener
                resultados en materia de coberturas y costos.
                <br></br>• Atención de manera personal en todo momento.
                <br></br>• Brindarle la experiencia de más de 50 años en la
                actividad aseguradora.
      </p>
    ),
  },
  {
    id: 3,
    pregunta: "¿Puedo ganar tiempo para dedicárselo a la venta?",
    respuesta: (
      <p itemprop="text">
        Claro que sí, ya que nosotros ejecutamos las acciones operativas
                necesarias para que usted dispense el tiempo a la venta.
      </p>
    ),
  },
  {
    id: 4,
    pregunta: "¿Tengo menos comisiones operando con Gestión y Servicios?",
    respuesta: (
      <p itemprop="text">
        No, las comisiones son las que le liquida la propia aseguradora
                al 100%.
      </p>
    ),
  },
  {
    id: 5,
    pregunta: "¿Debo abrir código en toda aseguradora que yo quiera operar?",
    respuesta: <p itemprop="text">Es lo conveniente. No obstante, en caso de que desee que Gestión
    y Servicios, por el motivo que fuere, opere como el “Productor”
    en algún seguro puntual, es factible manejarlo de esa manera.</p>,
  },
  {
    id: 6,
    pregunta: "¿Se requiere un número mínimo de prima para trabajar con Gestión y Servicios?",
    respuesta: <p itemprop="text">No, en absoluto. Elaboraremos distintos planes de acción para
    crecer.</p>,
  },
  {
    id: 7,
    pregunta: "¿Hay premios para el PAS?",
    respuesta: (
      <p itemprop="text">
        Si, ya que premiamos el esfuerzo. Brindamos premios cuando se
                alcanza un crecimiento determinado en base a las posibilidades
                del P.A S.
      </p>
    ),
  }
];
