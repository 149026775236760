import React, { Component } from "react";
import Lottie from "react-lottie";
import * as animationData from "./animation.json";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  margin: 0px 0px 0px 0px !important;
  pointer-events: none;

  div {
    width: 90% !important;
    height: 90% !important;
    margin: 50px 0px 0px 0px !important;
  }
`;

export default class Animacion extends Component {
  render() {
    const animationOptions = {
      loop: true,
      autoplay: true,
      renderer: "svg",
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <Container>
        <Lottie options={animationOptions} height={530} width={480} />
      </Container>
    );
  }
}
