// *** Contacto *** //
export const contactoReglas = (state) => {
    return {
        nombre: [
            {
                regla: state.nombre === "",
                mensaje: 'El campo nombre es requerido.'
            },
            {
                regla: state.nombre.length < 2,
                mensaje: 'El campo nombre debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.nombre),
                mensaje: 'El nombre ingresado no es válido.'
            },
        ],
        apellido: [
            {
                regla: state.apellido === "",
                mensaje: 'El campo nombre es requerido.'
            },
            {
                regla: state.apellido.length < 2,
                mensaje: 'El campo nombre debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.apellido),
                mensaje: 'El nombre ingresado no es válido.'
            },
        ],
        email: [
            {
                regla: state.email === "",
                mensaje: 'El campo apellido es requerido.'
            },
            {
                regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
                mensaje: 'El email ingresado no es válido.'
            },
        ],
        telefono: [
            {
                regla: state.telefono === "",
                mensaje: 'El campo teléfono es requerido.'
            },
            {
                regla: state.telefono.length < 8,
                mensaje: 'El campo telefono es menor a 8 digitos.'
            },
        ],
        mensaje: [
            {
                regla: state.mensaje === "",
                mensaje: 'El campo asunto es requerido.'
            }
        ],
    }
};

