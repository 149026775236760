import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  max-width: 1270px;
  margin: 70px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--white);

  // Title
  h1 {
    width: 100%;
    text-align: center;
    color: var(--blue);
    margin: 0px 0px 0px 0px;
  }

  // Text
  p {
    width: 100%;
    text-align: center;
    color: var(--black);
    margin: 13px 0px 0px 0px;
  }

  // Button
  a {
    margin: 25px 0px 0px 0px;
    padding: 10px 25px 10px 25px;
    border-radius: 6px;
    border: 0px solid;
    color: var(--white);
    background-color: var(--orange);
    cursor: pointer;
    transition: 0.2s;
    text-decoration: none;
  }

  // Button Hover
  a:hover {
    background-color: var(--lightorange);
  }

  // 1600px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1600}) {
    max-width: 1100px;
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    max-width: 1000px;
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    max-width: 850px;
  }

  // 1024px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
    max-width: 700px;
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    max-width: 600px;
  }

  // 768px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet768}) {
    max-width: 540px;
  }

  // 670px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet670}) {
    max-width: 480px;
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    max-width: 400px;
  }

  // 450px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile450}) {
    max-width: 88%;
  }
`;

export default Container;
