import * as React from "react";
import { Fade } from "react-awesome-reveal";
import Animacion from "./animacion";
import Container from "./styled";

export default function Propuesta() {
  return (
    <Container>
      {/* Max Width Container */}
      <div className="opacidad05">
        {/* Fade Container */}
        <Fade delay={100} duration={800} className="fade-container-texto">
          {/* Titulo Texto Container */}
          <div>
            {/* Titulo */}
            <h3>
              <span>Nuestra</span> propuesta
            </h3>
            {/* Texto */}
            <ul>
              <li>20 Compañías + Soporte comercial + estrecho vínculo con las
              Compañías : propuesta para aumentar tu cartera de clientes.</li>
              <li> Asistencia y soporte 24x7 : propuesta para disminuir carga en
              tareas administrativas y costos operativos. </li>
             <li>Técnica de venta tradicional y digital : propuesta de crecimiento y desarrollo</li> 
             <li>Harás de la actividad aseguradora te resulte más rentable</li>
            </ul>
          </div>
        </Fade>
        {/* Animación Container */}
        <Fade
          direction="left"
          delay={100}
          duration={800}
          className="animacion-container"
        >
          {/* Animación */}
          <Animacion></Animacion>
        </Fade>
      </div>
    </Container>
  );
}
