import styled from "styled-components";
import { breakpoints } from "../../constants/media";

const Container = styled.div`
  width: 100% !important;
  margin: 65px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-bottom: 1px solid var(--grisClaro);
  background-color: var(--blanco);
  /*
  position: fixed;
  top: 0;
  left: 0;
  */
  content: "";

  // Max Width Container
  #max-width-container {
    width: 1270px;
    padding: 56px 0px 56px 0px;
    position: relative;
  }

  // Botón Atras
  a {
    width: fit-content;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    color: var(--verde50);
    font-size: 13px;
    border: 0px;
    background-color: var(--blanco);
    cursor: pointer;
    transition: 0.2s all !important;
  }

  // Botón Atras Icono
  a span {
    margin: 4px 10px 0px 0px;
    font-size: 20px;
    color: var(--verde50);
    transform: translate(0, 0);
    transition: 0.2s all !important;
  }

  // Botón Atras Hover Icono
  a:hover > span {
    transform: translate(-10px, 0);
  }

  // Titulo
  h1 {
    margin: 16px 0px 0px 0px;
  }

  // Titulo Span
  h1 span {
    color: var(--negro);
  }

  // Subtitulo
  p {
    width: 550px;
    margin: 10px 0px 0px 0px;
    color: var(--gris);
    font-size: 14.5px;
  }

  // Ilustracion Container
  #ilustracion-container {
    width: auto;
    height: 313px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    position: absolute;
    bottom: 0;
    right: 0;

    // Ilustracion
    img {
      width: 190px;
      margin: 0px 0px 0px 0px;
      pointer-events: none;
    }
  }

  // 1600px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1600}) {
    // Max Width Container
    #max-width-container {
      max-width: 1150px;
    }
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    // Max Width Container
    #max-width-container {
      max-width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    #max-width-container {
      max-width: 800px;
    }
  }

  // 1024px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
    // Max Width Container
    #max-width-container {
      max-width: 600px;
    }

    // Ilustracion Container
    #ilustracion-container {
      display: none;
    }
  }

  // 768px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet768}) {
    // Max Width Container
    #max-width-container {
      max-width: 540px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    #max-width-container {
      max-width: 400px;
    }

    // Subtitulo
    p {
      width: 100%;
    }
  }

  // 450px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile450}) {
    // Max Width Container
    #max-width-container {
      max-width: 88%;
    }
  }
`;

export default Container;
