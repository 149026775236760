import * as React from "react";
import { Fade } from "react-awesome-reveal";
import Animacion from "../home/quienesSomos/animacion";
import Container from "./styled";

export default function Section() {
  return (
    <Container>
      {/* Max Width Container */}
      <div className="opacidad05">
        {/* Fade Container */}
        <Fade delay={100} duration={800} className="fade-container-texto">
          {/* Titulo Texto Container */}
          <div>
            {/* Titulo */}
            <h3>
              <span>Nuestra</span> historia
            </h3>
            {/* Texto */}
            <div className="descripcion-container">
            <p>
              La Familia Mareque es la fundadora de Gestion y Servicios por el
              año 1988. El fundador ha conducido y direccionado 3 aseguradoras,
              ejerciendo la Presidencia de la Cámara de Auto y de
              Responsabilidad Civil, miembro del Comité Ejecutivo de la
              Asociación Argentina de Cías. de Seguros durante 10 años;
              representante Técnico Argentino ante el Mercoseguros, nombrado por
              la Superintendencia de Seguros de la Nación. Adicionalmente, hemos
              creado el seguro de Bicicletas en la República Argentina.
            </p>
            <p>
              Toda la experiencia sembrada la volcamos hacia el Productor Asesor
              de Seguros con la finalidad que podamos ser de suma utilidad para
              que obtenga nuevos asegurados y desarrollar los existentes,
              implementando un alto valor de servicio el cual nos proponemos
              brindar. Sentimos pasión por lo que hacemos y por eso lo hacemos.
            </p>
            </div>
          </div>
        </Fade>
        {/* Animación Container */}
        <Fade
          direction="left"
          delay={100}
          duration={800}
          className="animacion-container"
        >
          {/* Animación */}
          <Animacion></Animacion>
        </Fade>
      </div>
    </Container>
  );
}
