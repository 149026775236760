import InputBasic from "../../InputBasic";
import InputTextarea from "../../InputTextarea";
import Button from "../../Button";
import Container from "./styled";
const ContactForm = ({ formValues, buttonText }) => {
  const {
    inputValues,
    handleChange,
    handleCheckbox,
    handleValidation,
    formErrors,
  } = formValues;

  return (
    <Container>
      <form>
        <div className="grid-container">
          <InputBasic
            label="Nombre"
            type="text"
            id="input-nombre"
            name="nombre"
            onChange={handleChange}
            onFocus={handleChange}
            onBlur={handleChange}
            value={inputValues.nombre}
            className={formErrors.nombre == null ? "" : "input-error"}
            error={formErrors.nombre}
            placeholder="Nombre"
          />
          <InputBasic
            label="Apellido"
            type="text"
            id="input-apellido"
            name="apellido"
            onChange={handleChange}
            onFocus={handleChange}
            onBlur={handleChange}
            value={inputValues.apellido}
            className={formErrors.apellido == null ? "" : "input-error"}
            error={formErrors.apellido}
            placeholder="Apellido"
          />
          <InputBasic
            label="Email"
            type="email"
            id="input-email"
            name="email"
            onChange={handleChange}
            onFocus={handleChange}
            onBlur={handleChange}
            value={inputValues.email}
            className={formErrors.email == null ? "" : "input-error"}
            error={formErrors.email}
            placeholder="tumail@email.com"
          />
          <InputBasic
            label="Teléfono"
            type="number"
            id="input-telefono"
            name="telefono"
            onChange={handleChange}
            onFocus={handleChange}
            onBlur={handleChange}
            value={inputValues.telefono}
            className={formErrors.telefono == null ? "" : "input-error"}
            error={formErrors.telefono}
            placeholder="1152632373"
          />
        </div>
        <div className="grid-container-textarea">
          <InputTextarea
            label="Mensaje"
            id="input-mensaje"
            name="mensaje"
            onChange={handleChange}
            onFocus={handleChange}
            onBlur={handleChange}
            value={inputValues.mensaje ? inputValues.mensaje : ""}
            className={formErrors.mensaje == null ? "" : "input-error"}
            error={formErrors.mensaje}
            placeholder="Ingresá tu mensaje"
          />
        </div>
        <div className="checkbox-container">
          <input type="checkbox" id="matricula" name="pas" onChange={handleCheckbox} />
          <label for="matricula">¿Tiene matrícula P.A.S?</label>
        </div>
        <Button onClick={handleValidation}>
          {buttonText}
        </Button>
      </form>
    </Container>
  );
};

export default ContactForm;
