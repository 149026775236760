export const ASEGURADORAS = [
    {
        logo: "images/logos/aseguradoras/federacionPatronal.svg",
        alt: "Logo de Federación Patronal",
        id: "federacionLogo",
        aseguradora: "Federación Patronal",
        link: "https://www.fedpat.com.ar/"
    },
    {
        logo: "images/logos/aseguradoras/allianz.svg",
        alt: "Logo de Allianz Seguros",
        id: "",
        aseguradora: "Allianz Seguros",
        link: "https://www.allianz.com.ar/"
    },
    {
        logo: "images/logos/aseguradoras/sanCristobal.svg",
        alt: "Logo de San Cristobal",
        id: "",
        aseguradora: "San Cristobal Seguros",
        link: "https://www.sancristobal.com.ar/institucional/"
    },
    {
        logo: "images/logos/aseguradoras/provinciaSeguros.svg",
        alt: "Logo de Provincia Seguros",
        id: "",
        aseguradora: "Provincia Seguros",
        link: "https://www.provinciaseguros.com.ar/"
    },
    {
        logo: "images/logos/aseguradoras/provinciaART.svg",
        alt: "Logo de Provincia ART",
        id: "",
        aseguradora: "Provincia ART",
        link: "https://www.provinciart.com.ar/"
    },
    {
        logo: "images/logos/aseguradoras/integritySeguros.svg",
        alt: "Logo de Integrity Seguros",
        id: "",
        aseguradora: "Integrity Seguros",
        link: "https://integrityseguros.com.ar/"
    },
    {
        logo: "images/logos/aseguradoras/nacionSeguros.svg",
        alt: "Logo de Nación Seguros",
        id: "",
        aseguradora: "Nación Seguros",
        link: "https://www.nacion-seguros.com.ar/"
    },
    {
        logo: "images/logos/aseguradoras/expertaSeguros.svg",
        alt: "Logo de Experta Seguros",
        id: "",
        aseguradora: "Experta Seguros",
        link: "https://www.experta.com.ar/"
    },
    {
        logo: "images/logos/aseguradoras/expertaART.svg",
        alt: "Logo de Experta ART",
        id: "",
        aseguradora: "Experta ART",
        link: "https://www.experta.com.ar/art/"
    },
    {
        logo: "images/logos/aseguradoras/atmSeguros.svg",
        alt: "Logo de ATM Seguros",
        id: "atmLogo",
        aseguradora: "ATM Seguros",
        link: "https://www.atmseguros.com.ar/"
    },
    {
        logo: "images/logos/aseguradoras/sancorSeguros.svg",
        alt: "Logo de Sancor Seguros",
        id: "sancorLogo",
        aseguradora: "Sancor Seguros",
        link: "https://www.sancorseguros.com.ar/"
    },
    {
        logo: "images/logos/aseguradoras/prevencionART.svg",
        alt: "Logo de Prevención ART",
        id: "",
        aseguradora: "Prevención ART",
        link: "https://www.prevencionart.com.ar/"
    },
    {
        logo: "images/logos/aseguradoras/prudencia.png",
        alt: "Logo de Prudencia Seguros",
        id: "",
        aseguradora: "Prudencia Seguros",
        link: "https://www.prudenciaseguros.com.ar/"
    },
    {
        logo: "images/logos/aseguradoras/plenariaSeguros.svg",
        alt: "Logo de Plenaria Seguros",
        id: "",
        aseguradora: "Plenaria Seguros",
        link: "https://www.plenariaseguros.com.ar/"
    },
    {
        logo: "images/logos/aseguradoras/paranaSeguros.svg",
        alt: "Logo de Paraná Seguros",
        id: "paranaLogo",
        aseguradora: "Paraná Seguros",
        link: "https://www.paranaseguros.com.ar/"
    },
    {
        logo: "images/logos/aseguradoras/colonSeguros.svg",
        alt: "Logo de Colón Seguros",
        id: "colonLogo",
        aseguradora: "Colón Seguros",
        link: "https://www.colonseguros.com.ar/"
    },
    {
        logo: "images/logos/aseguradoras/laSegunda.svg",
        alt: "Logo de La Segunda Seguros",
        id: "",
        aseguradora: "La Segunda Seguros",
        link: "https://www.lasegunda.com.ar/home"
    },
    {
        logo: "images/logos/aseguradoras/mapfre.svg",
        alt: "Logo de Mapfre Seguros",
        id: "",
        aseguradora: "Mapfre Seguros",
        link: "https://segurosmapfre.mapfre.es/"
    },
    {
        logo: "images/logos/aseguradoras/hdiSeguros.svg",
        alt: "Logo de HDI Seguros",
        id: "",
        aseguradora: "HDI Seguros",
        link: "https://www.hdi.com.ar/wps/portal/hdi/"
    },
    {
        logo: "images/logos/aseguradoras/metlife.svg",
        alt: "Logo de MetLife Seguros",
        id: "",
        aseguradora: "MetLife Seguros",
        link: "https://www.metlife.com.ar/"
    },
]