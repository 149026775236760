import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  padding: 24px 24px 40px 24px;
  border: 1px solid var(--grisClaro);
  border-radius: 12px;
  align-items: center;

  .container img {
    width: 180px;
    pointer-events: none;
    margin: 0 auto;
  }
    // Cards Title
  .container h3 {
    width: 100%;
    text-align: center;
    font-size: 18px !important;
    color: var(--verde50);
    font-weight: 500;
    line-height: 1.5;
    margin: 0;
  }

    // Card Texto
  .container p {
    text-align: center;
    font-size: 14.5px !important;
    line-height: 30px;
    color: var(--gris);
    margin: 0;
  }
`;

export default Container;
