import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  padding: 90px 0px 120px 0px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  background-color: var(--blanco);
  border-top: 1px var(--grisClaro) solid;
  z-index: 91;

  // Max Width Container
  #max-width-container {
    width: 100%;
    max-width: 1270px;
    background-color: var(--blanco);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Fade Container Titulo
  .fade-container-titulo {
    width: 100%;
    margin: 0px 0px 64px 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Titulo
  h3 {
    width: 100%;
    margin: 0px 0px 0px 0px;
    text-align: center;
    color: var(--negro);
    font-weight: 500;

    span {
      color: var(--azul60);
      font-weight: 500;
    }
  }

  // Columnas
  #columna-izquierda,
  #columna-derecha {
    width: 45%;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border: 1px solid var(--grisClaro);
    border-radius: 8px;
  }

  .span-2 {
    grid-column: span 2;
  }

  .border-top {
    border-top: 1px solid var(--grisClaro);
  }

  .border-right {
    border-right: 1px solid var(--grisClaro);
  }

  // Fade Container
  .fade-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    box-sizing: border-box;
    padding: 20px 40px;
  }

  // Texto
  p {
    color: var(--gris);
    line-height: 32px;
    font-size: 14.5px;
  }

  ul {
    padding-left: 12px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  ul li {
    width: 90%;
    color: var(--gris);
    line-height: 32px;
    font-size: 14.5px;
    font-family: "DM Sans";
  }

  strong {
    font-family: "DM Sans";
  }

  // Texto Span
  span {
    font-weight: 600;
    font-size: 24px;
    color: var(--verde50);
  }

  // 1600px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1600}) {
    // Max Width Container
    #max-width-container {
      max-width: 1150px;
    }
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    // Max Width Container
    #max-width-container {
      max-width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    #max-width-container {
      max-width: 800px;
    }
  }

  // 1024px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
    // Max Width Container
    #max-width-container {
      max-width: 600px;
    }

    // Columnas
    #columna-izquierda,
    #columna-derecha {
      width: 100%;
    }

    .span-2 {
      grid-column: span 1;
    }

    .grid-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  // 768px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet768}) {
    // Max Width Container
    #max-width-container {
      max-width: 540px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    #max-width-container {
      max-width: 400px;
    }
  }

  // 450px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile450}) {
    // Max Width Container
    #max-width-container {
      max-width: 88%;
    }
  }
`;

export default Container;
