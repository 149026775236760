import React from "react";
import { bool, func } from "prop-types";
import Container from "./styled";
import disableScroll from "disable-scroll";
import { HiMenuAlt2 } from "react-icons/hi";

export default function Burger({ open, setOpen }) {
  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <Container open={open} onClick={toggleOpen}>
      {/* Botón Abrir */}
      <button onClick={toggleOpen}>
        <HiMenuAlt2></HiMenuAlt2>
      </button>
    </Container>
  );
}
