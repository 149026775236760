import { Fade } from "react-awesome-reveal";
import Container from "./styled";
import { ASEGURADORAS } from "../../constants/aseguradoras";

export default function AseguradorasCards() {
  return (
    <Container>
      <div>
        <div>
          {ASEGURADORAS.map(aseguradora => {
            return (
              <Fade className="fadeContainer" delay={100} duration={800} key={aseguradora.aseguradora}>
                <a target="_blank" href={aseguradora.link} rel="noreferrer">
                  <img
                    alt={aseguradora.alt}
                    src={aseguradora.logo}
                    id={aseguradora?.id}
                  ></img>
                  <p className="title">{aseguradora.aseguradora}</p>
                </a>
              </Fade>
            )
          })}
        </div>
      </div>
    </Container>
  );
}
