import * as React from "react";
import { Fade } from "react-awesome-reveal";
import Animacion from "./animacion";
import Container from "./styled";

export default function QuienesSomos() {
  return (
    <Container>
      {/* Max Width Container */}
      <div className="opacidad05">
        {/* Fade Container */}
        <Fade delay={100} duration={800} className="fade-container-texto">
          {/* Titulo Texto Container */}
          <div>
            {/* Titulo */}
            <h3>
              ¿Quienes <span>somos</span>?
            </h3>
            {/* Texto */}
            <div className="descripcion-container">
              <p>
              Somos una empresa especializada en brindar soporte y servicio a los Productores
              Asesores de Seguros, con el respaldo de más de 50 años dentro del mercado de
              seguros.
              </p>
              <p>
              Nuestro propósito y compromiso es mantener a los Productores en pleno crecimiento y
              desarrollo profesional, aportando desde Gestión y Servicios Broker de seguros, un
              importante aporte comercial y técnico logrando así, brindar una experiencia de alto
              valor.
              </p>
            </div>
          </div>
        </Fade>
        <Fade
          direction="right"
          delay={100}
          duration={800}
          className="animacion-container"
        >
          {/* Animación */}
          <Animacion></Animacion>
        </Fade>
      </div>
    </Container>
  );
}
