import * as React from "react";
import Container from "./styled";
import { BsArrowLeft } from "react-icons/bs";
import { Link as Boton } from "react-router-dom";

export default function HeaderSection({ title, description }) {
  return (
    <Container>
      {/* Max Width Container */}
      <div id="max-width-container" className="opacidad02">
        {/* Botón Atras */}
        <Boton to="/" className="opacidad03">
          <span>
            <BsArrowLeft />
          </span>
          Inicio
        </Boton>
        {/* Titulo */}
        <h1 className="opacidad03">
          <span>{title}</span>
        </h1>
        {/* Subtitulo */}
        <p>
          {description}
        </p>
      </div>
    </Container>
  );
}
