import React from "react";
import styled from "styled-components";
import Header from "../components/Header";
import TextButton from "../components/error/textButton";
import ErrorAnimation from "../components/error/lottieAnimation";
import Footer from "../components/Footer";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--white);
`;

export default function ErrorContainer() {
  return (
    <Container>
      <Header></Header>
      <ErrorAnimation></ErrorAnimation>
      <TextButton></TextButton>
      <Footer></Footer>
    </Container>
  );
}
