import React from "react";
import Container from "./styled";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

export default function Banner() {
  return (
    <Container>
      {/* Max Width Container */}
      <div>
        {/* Data Container */}
        <div className="opacidad05" id="data-container">
          {/* Titulo */}
          <h2>¿Sos productor de seguros?</h2>
          {/* Texto */}
          <p>
            En Gestión y Servicios estamos al servicio del productor para
            brindarle todos los medios necesarios para que desarrolle al máximo
            su potencial.
          </p>
          {/* Botón Consultanos */}
          <Link to="/consultanos">
            Consultanos
            <span>
              <BsArrowRight />
            </span>
          </Link>
        </div>
        {/* Gradiente Container */}
        <div id="gradiente-container"></div>
      </div>
    </Container>
  );
}
