import React from "react";
import { Routes, Route } from "react-router-dom";
import GlobalStyle from "./constants/globalStyles";
import Inicio from "./pages/Inicio";
import Aseguradoras from "./pages/Aseguradoras"
import Consultanos from "./pages/Consultanos";
import Error from "./pages/error-page";
import SobreNosotros from "./pages/SobreNosotros"
import { Construccion } from "./pages/construccion-page";
import Servicios from "./pages/Servicios";
import Decalogo from "./pages/Decalogo"

function App() {
  return (
    <div className="App">
      <Routes>
        {/* <Route path="/" element={<Construccion />} /> */}
        {/* Home */}
         <Route exact path="/" element={<Inicio />} /> 
        {/* Aseguradoras */}
        <Route exact path="/aseguradoras" element={<Aseguradoras />} /> 
        {/* Decálogo */}
        <Route exact path="/decalogo" element={<Decalogo />} /> 
        {/* Servicios */}
        <Route exact path="/servicios" element={<Servicios />} /> 
        <Route exact path="/sobre-nosotros" element={<SobreNosotros />} /> 

        {/* Consultanos */}
        <Route exact path="/consultanos" element={<Consultanos />} />  
        {/* Error Page */}
      </Routes>
      <GlobalStyle></GlobalStyle>
    </div>
  );
}

export default App;
