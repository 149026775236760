import React, { useState } from "react";
import Container from "./styled";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <Container>
      {/* Data Nav Container */}
      <div className="data-nav-container">
        {/* Data Container */}
        <div className="data-container">
          {/* Gestion y Servicio Logo */}
          <a href="/">
            <img alt="Gestón y Servicios Logo" src="images/logos/gys.svg" />
          </a>
          {/* Social Media Mobile Container */}
          <div className="social-media-mobile-container">
            {/* Facebook Button */}
            <a
              href="https://www.facebook.com/gyssrl.broker/"
              target="_blank"
              rel="nofollow"
            >
              <FaFacebookF />
            </a>
            {/* Instagram Button */}
            <a
              href="https://www.instagram.com/gyssrl/"
              target="_blank"
              rel="nofollow"
            >
              <BsInstagram />
            </a>
            {/* WhatsApp Button */}
            <a
              href="https://api.whatsapp.com/send?phone=541138043972&text=%C2%A1Hola%2C%20me%20gustar%C3%ADa%20obtener%20m%C3%A1s%20informaci%C3%B3n%20sobre%20GyS"
              target="_blank"
              rel="nofollow"
            >
              <BsWhatsapp />
            </a>
            {/* LinkedIn Button */}
            <a
              href="https://www.linkedin.com/company/gyssrl/"
              target="_blank"
              rel="nofollow"
            >
              <FaLinkedinIn />
            </a>
          </div>
          {/* Data */}
          <div className="data">
            <a href="tel:08102202373" rel="nofollow">
              0810-220-2373
            </a>
            <a href="tel:1152632373" rel="nofollow">
              (011) 5263-2373
            </a>
            <p>info@gyssrl.com.ar</p>
            <p>Suipacha 245 1° Piso CABA</p>
          </div>
        </div>
        {/* Navs Container */}
        <div className="navs-container">
          {/* Columna */}
          <div className="navs-container-columna" id="mapa-del-sitio">
            <p>Mapa del sitio</p>
            <nav>
              <li>
                <Link to="/">Inicio</Link>
              </li>
              <li>
                <Link to="/decalogo">Decálogo</Link>
              </li>
              <li>
                <Link to="/aseguradoras">Aseguradoras</Link>
              </li>
              <li>
                <Link to="/sobre-nosotros">Sobre nosotros</Link>
              </li>
              <li>
                <Link to="/servicios">Servicios</Link>
              </li>
              <li>
                <Link to="/consultanos">Consultanos</Link>
              </li>
              <li>
                <a
                  href="https://gyssrl.com.ar/productores/index.php"
                  target="_blank"
                >
                  Ingreso PAS
                </a>
              </li>
            </nav>
          </div>
          {/* Columna */}
          <div className="navs-container-columna" id="cotiza-tu-seguro">
            <p>Cotizá tu seguro</p>
            <nav>
              <li>
                <a href="https://www.segurobici.com.ar/" target="_blank">
                  SeguroBici
                </a>
              </li>
              <li>
                <a href="https://www.saludseguraonline.com.ar/" target="_blank">
                  SaludSegura
                </a>
              </li>
              <li>
                <a href="https://www.seguroweb.com.ar/" target="_blank">
                  SeguroAuto
                </a>
              </li>
              <li>
                <a href="https://www.segurohogar.com.ar/" target="_blank">
                  SeguroHogar
                </a>
              </li>
              <li>
                <a href="https://www.seguromotos.com.ar/" target="_blank">
                  SeguroMotos
                </a>
              </li>
              <li>
                <a href="https://www.seguromascotas.com.ar/" target="_blank">
                  SeguroMascotas
                </a>
              </li>
              <li>
                <a href="https://comercio.seguroweb.com.ar/" target="_blank">
                  SeguroComercio
                </a>
              </li>
            </nav>
          </div>
        </div>
      </div>
      {/* Social Media y Logos Container */}
      <div className="social-media-logos-container">
        {/* Social Media Container */}
        <div className="social-media-container">
          {/* Facebook Button */}
          <a
            href="https://www.facebook.com/gyssrl.broker/"
            target="_blank"
            rel="nofollow"
          >
            <FaFacebookF />
          </a>
          {/* Instagram Button */}
          <a
            href="https://www.instagram.com/gyssrl/"
            target="_blank"
            rel="nofollow"
          >
            <BsInstagram />
          </a>
          {/* WhatsApp Button */}
          <a
            href="https://api.whatsapp.com/send?phone=541138043972&text=%C2%A1Hola%2C%20me%20gustar%C3%ADa%20obtener%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Gesti%C3%B3n%20y%20Servicios!"
            target="_blank"
            rel="nofollow"
          >
            <BsWhatsapp />
          </a>
          {/* LinkedIn Button */}
          <a
            href="https://www.linkedin.com/company/gyssrl/"
            target="_blank"
            rel="nofollow"
          >
            <FaLinkedinIn />
          </a>
        </div>
        {/* Logos Container */}
        <div className="logos-container">
          {/* Logo */}
          <a
            href="https://www.gyssrl.com.ar/webroot/footer/certificadoapt.pdf"
            target="_blank"
            rel="nofollow"
          >
            <img alt="Logo SSN" src="images/logos/ssn.png" id="logoSSN"></img>
          </a>
          {/* Logo */}
          <img alt="Logo PDP" src="images/logos/pdp.png" id="logo-PDP"></img>
          {/* Texto */}
          {/* Logo */}
          <a
            href="https://servicios1.afip.gov.ar/clavefiscal/qr/response.aspx?qr=vfDql9TuSM09b_FRxAacFg,,"
            target="_F960AFIPInfo"
            id="logo-fiscal"
            rel="nofollow"
          >
            <img
              alt="Logo Data Fiscal"
              src="images/logos/datafiscal.webp"
            ></img>
          </a>
          <p>
            Superintendencia de Seguros de la Nación - Organo de Control de la
            Actividad Aseguradora y Reaseguradora. 0800-666-8400 -
            www.ssn.gob.ar - Nº de inscripción: 387 - Certificado de Aptitud
          </p>
        </div>
        <img
          alt="Edificios del isotipo de Gestión y Servicios"
          src="/images/footer/edificios-borde.svg"
          id="edificios"
        ></img>
      </div>
      {/* Texto Copyright */}
      <div id="texto-copyright-container">
        <p>
          © Copyright 2024 Gestión y Servicios SRL | Todos los derechos
          reservados
        </p>
      </div>
    </Container>
  );
}
