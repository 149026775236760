import { Fade } from "react-awesome-reveal";
import Beneficio from "./Beneficio";
import { Container } from "./styled";
import { BENEFICIOS } from "../../../constants/beneficios";
import { useQuestion } from "../../../hooks/useQuestion";

export default function Beneficios() {
  const { isQuestionOpen, handleToggle } = useQuestion();

  return (
    <Container>
      {/* Max Width Container */}
      <div id="max-width-container">
        {/* Titulo */}
        <Fade className="fade-container-titulo" delay={50} duration={700}>
          <h3>
            Obtenga grandes <span>beneficios</span>
          </h3>
        </Fade>
        <div id="beneficios-container">
          {BENEFICIOS.map((faq, index) => {
            return (
              <Fade
                key={faq.id}
                delay={100}
                duration={600}
                className={
                  isQuestionOpen === index
                    ? "beneficio-container-abierta"
                    : "beneficio-container"
                }
              >
                <Beneficio
                  title={faq.pregunta}
                  clickFunction={() => handleToggle(index)}
                  isActive={isQuestionOpen === index}
                >
                  {faq.respuesta}
                </Beneficio>
              </Fade>
            );
          })}
        </div>
      </div>
    </Container>
  );
}
