import Container from "./styled"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import HeaderSection from "../../components/HeaderSection";
import QuienesSomos from "../../components/home/quienesSomos";
import Propuesta from "../../components/home/propuesta";
import Section from "../../components/Section";
import { useScrollTop } from "../../hooks/useScrollTop";

const SobreNosotros = () => {
  useScrollTop()
  return (
    <Container>
      <Header active="sobre-nosotros"></Header>
      <HeaderSection title={"Sobre Nosotros"} description={"Somos una empresa especializada en brindar soporte y servicio a los Productores Asesores de Seguros, con el respaldo de más de 50 años en el mercado."} />
      <Section />
      <Propuesta />
      <Footer />
    </Container>
  );
}

export default SobreNosotros