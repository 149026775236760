import Container from "./styled"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import HeaderSection from "../../components/HeaderSection";
import QuienesSomos from "../../components/home/quienesSomos";
import Propuesta from "../../components/home/propuesta";
import Section from "../../components/Section";
import { useScrollTop } from "../../hooks/useScrollTop";
import AseguradorasCards from "../../components/AseguradorasCards";

const Aseguradoras = () => {
  useScrollTop()
  return (
    <Container>
      <Header active="aseguradoras"></Header>
      <HeaderSection title={"Aseguradoras"} description={"En Gestión y Servicios contamos con basta experiencia respaldada por una extensa lista de productores que confian en nosotros."} />
      <AseguradorasCards />
      <Footer />
    </Container>
  );
}

export default Aseguradoras