import React from 'react'
import Container from './styled'

const InnerContainer = ({ children, center }) => {
  return (
    <Container center={center}>
        {children}
    </Container>
  )
}

export default InnerContainer