import * as React from "react";
import { Collapse } from "react-collapse";
import Container from "./styled";

const Beneficio = ({ isActive, clickFunction, children, title}) => {
    return (
      <Container>
        <button
          className={isActive ? "header-abierto" : "header-cerrado"}
          onClick={clickFunction}
        >
          <h4 itemprop="name">{title}</h4>
          <img
            id="buttonIcon"
            alt={isActive ? "Cerrar" : "Abrir"}
            src="/images/home/faqs/arrow.svg"
          ></img>
        </button>
        <Collapse isOpened={isActive}>
          <div className="texto">
            <p>{children}</p>
          </div>
        </Collapse>
      </Container>
    ); 
}

export default Beneficio;
