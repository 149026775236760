import Container from "./styled"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import HeaderSection from "../../components/HeaderSection";
import { useScrollTop } from "../../hooks/useScrollTop";
import ServiciosCards from "../../components/servicios/cards";
import Beneficios from "../../components/servicios/Beneficios";
import SectionHome from "../../components/SectionHome";
import Contacto from "../../components/Contacto";

const Servicios = () => {
  useScrollTop()
  return (
    <Container>
      <Header active="servicios"></Header>
      <HeaderSection title={"Servicios"} description={"Brindamos al productor los medios necesarios para desarrollar al máximo su potencial ofreciendo soluciones y beneficios."} />
      <ServiciosCards />
      <Beneficios />
      <SectionHome>
        <Contacto />
      </SectionHome>
      <Footer />
    </Container>
  );
}

export default Servicios