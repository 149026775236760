export const BENEFICIOS = [
  {
    id: 1,
    pregunta: "Aumente su nivel de ingresos",
    respuesta: (
      <p itemprop="text">
        - Aumentar su cartera de clientes.
        <br />
        - Disminuir sus costos operativos.
        <br />
        - Aumentar su poder ante compañías de 1er. Nivel.
        <br />
        - Brindamos los medios necesarios para que desarrolle al máximo su
        potencial.
        <br />- Obtendrá premios por producción.
      </p>
    ),
  },
  {
    id: 2,
    pregunta: "Capacitación en ventas",
    respuesta: (
      <p itemprop="text">
        - Mejorar técnicas de venta y de cierre.
        <br />
        - Aplicar técnicas de venta digitales a los mecanismos de venta
        tradicional.
        <br />
        - Fidelizar a tu cliente vendiéndole más productos. Ganar más clientes y
        referidos.
        <br />
        - Incrementar tus ingresos vendiendo más y mejor.
        <br />- Cursos de crosselling y referidos, objeciones, mejorar la venta
        telefónica, mejorar el proceso de venta, asesoramiento en e-mail
        marketing, LinkedIn, sitio web y redes sociales.
      </p>
    ),
  },
  {
    id: 3,
    pregunta: "Sea parte de Gestión y Servicios",
    respuesta: (
      <p itemprop="text">
        - Aumentará su cartera de clientes manteniendo su nivel de rentabilidad
        actual.
        <br />
        - Disminuirá su carga laboral en tareas administrativas y sus costos
        operativos.
        <br />
        - Profesionalización.
        <br />
        - Obtendrá mayor poder ante las compañías de seguro de 1er nivel.
        <br />- Servicios Especiales.
      </p>
    ),
  },
];
