import React from "react";
import { Fade } from "react-awesome-reveal";
import Marquee from "react-fast-marquee";
import Container from "./styled";
import { ASEGURADORAS } from "../../../constants/aseguradoras";
import { Link } from "react-router-dom";
export default function Logos() {
  return (
    <Container>
      <div id="maxWidthContainer">
        {/* Titulo */}
        <Fade className="fade-container-tiulo" delay={50} duration={700}>
          <h3>
            Compañias con las que <span>trabajamos</span>
          </h3>
        </Fade>
        {/* Marquee Container */}
        <Fade className="fade-container-marquee" delay={50} duration={700}>
          <Marquee gradient={true} speed="45">
            {ASEGURADORAS.map((aseguradora, index) => {
              return (
                <div className={`logo-container ${ASEGURADORAS.length - 1 === index ? "last-item" : ""}`}>
                  <img
                    alt={aseguradora.alt}
                    src={aseguradora.logo}
                    id={aseguradora?.id}
                  />
                </div>
              );
            })}
          </Marquee>
        </Fade>
        {/* Botón Ver Todos */}
        <Link to="/aseguradoras">Ver compañias</Link>
      </div>
    </Container>
  );
}
