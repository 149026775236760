import { useState } from "react";
import useValidation from "./useValidation";

export const useForm = (submitCallback, rules, initialValues, extraData) => {
  const [inputValues, setInputValues] = useState(initialValues);
  const { validateForm, formErrors } = useValidation(rules(inputValues, extraData));

  const handleChange = (e) => {
    const { name, value } = e.target
    setInputValues((prev) => ({ ...prev, [name]: value }))
  };

  const handleCheckbox = (e) => {
    const { name, checked } = e.target
    setInputValues((prev) => ({ ...prev, [name]: checked }))
  }

  // Para elementos del form que no poseen atributo name (captcha)
  const handleChangeExternal = (fieldName, fieldValue) => {
    setInputValues((prev) => ({ ...prev, [fieldName]: fieldValue }));
  }

  const resetForm = () => { 
    setInputValues(initialValues)
  }

  // Validar Formulario
  const handleValidation = (e) => {
    e.preventDefault();
    const formValidation = validateForm();
    console.log(formValidation)
    if (Object.keys(formValidation).length === 0) 
      submitCallback()
  };

  return {
    inputValues,
    handleChange,
    handleCheckbox,
    handleValidation,
    formErrors,
    handleChangeExternal,
    resetForm
  };
};
