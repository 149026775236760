import React, { useState, useEffect } from "react";
import Container from "./styled";
import Burger from "./burger";
import Menu from "./menu";
import disableScroll from "disable-scroll";
import { MdOutlinePersonOutline, MdZoomIn } from "react-icons/md";
import { Link } from "react-router-dom";

export default function Header({ active }) {
  const [open, setOpen] = useState(false);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
          disableScroll.off();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <Container>
      <div id="logo-nav-container">
        <Burger open={open} setOpen={setOpen} />
        <Menu
          open={open}
          setOpen={setOpen}
          active={active}
          useOutsideAlerter={useOutsideAlerter}
        />
        <Link to="/">
          <img alt="Gestón y Servicios Logo" src="/images/logos/gys.svg" />
        </Link>
        <nav className="navDesktop">
          <ul>
            <li>
              <Link to="/" className={active == "inicio" && "boton-activo"}>
                Inicio
              </Link>
            </li>
            <li>
              <Link
                to="/decalogo"
                className={active == "decalogo" && "boton-activo"}
              >
                Decálogo
              </Link>
            </li>
            <li>
              <Link
                to="/aseguradoras"
                className={active == "aseguradoras" && "boton-activo"}
              >
                Aseguradoras
              </Link>
            </li>
            <li>
              <Link
                to="/servicios"
                className={active == "servicios" && "boton-activo"}
              >
                Servicios
              </Link>
            </li>
            <li>
              <Link
                to="/sobre-nosotros"
                className={active == "sobre-nosotros" && "boton-activo"}
              >
                Sobre Nosotros
              </Link>
            </li>
            <li>
              <Link
                to="/consultanos"
                className={active == "consultanos" && "boton-activo"}
              >
                Consultanos
              </Link>
            </li>
            <li id="pas">
              <a
                href="https://gyssrl.com.ar/productores/index.php"
                target="_blank"
                rel="noreferrer"
              >
                <span>
                  <MdOutlinePersonOutline></MdOutlinePersonOutline>
                </span>
                <p>Ingreso P.A.S.</p>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </Container>
  );
}
