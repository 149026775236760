import styled from "styled-components";
import { breakpoints } from "../../constants/media";

const Container = styled.div`
  max-width: 1270px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 100px;

  .tituloMobile {
    display: none;
  }

  .container-descripcion {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;

    .titulo {
      text-align: left;
      margin: 0;
      font-weight: 500;
      color: var(--negro);
    }

    .titulo span {
      color: var(--azul60);
    }

    h4 {
      font-weight: 400;
      color: var(--negro);
      margin: 16px 0;
      font-size: 14px;
    }

    .descripcion {
      text-align: left;
      margin: 0;
      color: var(--gris);
      width: 80%;
    }

    .medios-container {
      display: flex;
      flex-direction: column;

      .medios {
        display: flex;
        gap: 20px;
      }

      .whatsapp {
        color: var(--verde50);
      }

      a {
        margin: 0px 0px 0px 0px;
        display: flex;
        text-decoration: none;
        color: var(--azul60);
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 192.7%;
        opacity: 1;
        cursor: pointer;
        transition: 0.2s;
        gap: 6px;

        span {
          font-size: 14px;
          color: var(--azul60);
          display: flex;
          align-items: center;
        }

        // Hover
        :hover {
          opacity: 0.7;

          span {
            opacity: 0.8;
          }
        }
      }
    }
  }

  .direccion-container {
    span {
      font-size: 14px;
      color: var(--gris);
      display: flex;
      gap: 6px;
      align-items: center;
      span {
        display: flex;
      }
    }
  }

  .container-form {
    width: 50%;
  }

  // Social Media y Logos Container
  .social-media-logos-container {
    width: 100%;
    max-width: 1270px;
    padding: 32px 0px 80px 0px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    align-content: flex-end;
    position: relative;
  }

  // Social Media Container
  // Social Media Mobile Container
  .social-media-container,
  .social-media-mobile-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  // Socil Media Mobile Container
  .social-media-mobile-container {
    display: none;
  }

  // Social Media Container Buttons
  // Social Media Mobile Container Buttons
  .social-media-container a,
  .social-media-mobile-container a {
    width: 33px;
    height: 33px;
    border-radius: 100%;
    background-color: var(--azul60);
    cursor: pointer;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: var(--blanco);
    font-size: 15px;
    transition: 0.2s;
  }

  // Social Media Buttons Hover
  .social-media-container a:hover {
    background-color: var(--grisClaro2);
    color: var(--azul60);
    transform: scale(1.4);
  }

  // 1600px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1600}) {
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    .container-descripcion {
      width: 100%;
      order: 2;
    }

    .descripcion,
    .titulo {
      display: none;
    }

    .tituloMobile {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin: 0;
      font-weight: 500;
      color: var(--negro);
      line-height: 1.5;
      margin-bottom: 40px;
    }

    .tituloMobile span {
      color: var(--azul60);
    }

    .container-form {
      width: 100%;
      order: 1;
      margin-bottom: 40px;
    }
  }

  // 1024px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
  }

  // 768px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet768}) {
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
  }

  // 450px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile450}) {
  }
`;

export default Container;
