import Container from "./styled"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import HeaderSection from "../../components/HeaderSection";
import { useScrollTop } from "../../hooks/useScrollTop";
import Contacto from "../../components/Contacto";

const Aseguradoras = () => {
  useScrollTop()
  
  return (
    <Container>
      <Header active="consultanos"></Header>
      <HeaderSection title={"Consultanos"} />
      <div className="margin">
        <Contacto />
      </div>      
      <Footer />
    </Container>
  );
}

export default Aseguradoras