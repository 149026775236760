import styled from "styled-components";
import { breakpoints } from "../../constants/media";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  h3 {
    margin-bottom: 40px;
  }
  .boton-container {
    margin-top: 64px;
  }
  // Cards Containers
  .grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    grid-auto-rows: minmax(auto, auto);
  }

    // Botón
    a {
    width: fit-content;
    padding: 16px 24px 16px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: var(--blanco);
    text-decoration: none;
    background-color: var(--azul40);
    border-radius: 8px;
    transition: 0.2s all;
    cursor: pointer;
  }

  // Botón Ícono
  a span {
    margin: 0px 8px 0px 0px;
  }

  // Botón Hover
  a:hover {
    background-color: var(--azul0);
  }


  // 1600px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1600}) {
    /*
    // Max Width Container
    #max-width-container {
      max-width: 1150px;
    }

    // Card Container
    .fade-container-card {
      max-width: calc(320px - 48px);
    }
    */
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    .grid-container {
      grid-template-columns: repeat(2, 1fr);
    }
    /*
    // Max Width Container
    #max-width-container {
      max-width: 1000px;
    }

    // Card Container
    .fade-container-card {
      max-width: calc(300px - 48px);
      margin: 80px 0px 0px 0px;
      min-height: 454px;
    }

    .grid-container {
      grid-template-columns: repeat(1, 1fr);
    }
    */
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    /*
    // Max Width Container
    #max-width-container {
      max-width: 800px;
    }

    // Card Container
    .fade-container-card {
      width: calc(45% - 48px);
      max-width: none;
      min-height: 397px;
      margin: 60px 0px 0px 0px;
    }

    .grid-container {
      grid-template-columns: repeat(1, 1fr);
    }
    */
  }

  // 1024px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
    .grid-container {
      grid-template-columns: repeat(1, 1fr);
    }
    /*
    // Max Width Container
    #max-width-container {
      max-width: 600px;
    }

    // Card Container
    .fade-container-card {
      width: calc(44% - 24px);
      min-height: 448px;
      padding: 16px 16px 40px 16px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      align-content: flex-start;
    }

    // Cards Title
    .fade-container-card div h3 {
      height: 48px;
    }

    .grid-container {
      grid-template-columns: repeat(1, 1fr);
    }
    */
  }

  // 768px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet768}) {
    .grid-container {
      grid-template-columns: repeat(1, 1fr);
    }
    /*
    // Max Width Container
    div {
      max-width: 540px;
    }

    // Card Container
    .fade-container-card {
      width: calc(44% - 24px);
      min-height: 478px;
    }

    // Card Imagen
    .fade-container-card div img {
      width: 97%;
      max-height: none;
      min-height: none;
    }

    .grid-container {
      grid-template-columns: repeat(1, 1fr);
    }
    */
  }

  // 651px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet670}) {
    .grid-container {
      grid-template-columns: repeat(1, 1fr);
    }
    /*
    // Max Width Container
    div {
      max-width: 400px;
    }

    // Card Container
    .fade-container-card {
      width: calc(100% - 24px);
      height: auto;
    }

    .grid-container {
      grid-template-columns: repeat(1, 1fr);
    }
    */
  }

  // 450px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile450}) {
    /*
    .grid-container {
      grid-template-columns: repeat(1, 1fr);
    }
    */
  }
`;

export default Container;
