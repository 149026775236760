import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  //margin: 368px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  background-color: var(--blanco);
  z-index: 1;

  // Max Width Container
  #max-width-container {
    width: 100%;
    max-width: 1270px;
    margin: 80px 0px 100px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Fade Container Titulo
  .fade-container-titulo {
    width: 100%;
    margin: 0px 0px 16px 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Titulo
  h3 {
    width: 100%;
    margin: 0px 0px 0px 0px;
    text-align: center;
    color: var(--negro);
    font-weight: 500;

    span {
      color: var(--azul60);
      font-weight: 500;
    }
  }

  // Cards Containers
  div div {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Cards
  div div div {
    width: 25%;
    max-width: 230px;
    min-height: 80px;
  }

  // Cards Container
  div div div div {
    width: 100%;
    padding: 0px 8px 0px 8px;
    margin: 50px 24px 0px 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 12px;
    border: 1px solid var(--grisClaro);
    //box-shadow: 0px 5px 30px rgba(34, 64, 155, 0.15);
  }

  .shimmer {
    background-image: linear-gradient(
      45deg,
      #ffffff 0%,
      #ffffff 40%,
      #f1f8ff 50%,
      #f1f8ff 55%,
      #ffffff 65%,
      #ffffff 100%
    );

    background-size: 400%;
    animation: shimmer 3000ms infinite;
  }

  @keyframes shimmer {
    from {
      background-position: 100% 100%;
    }
    to {
      background-position: 0 0;
    }
  }

  // Cards Title
  div div div h3 {
    width: 100%;
    color: var(--verde50);
    text-align: center;
    margin: 0px 0px 0px 0px;
    font-size: 17px;
  }

  // 1600px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1600}) {
    // Max Width Container
    #max-width-container {
      max-width: 1150px;
    }
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    // Max Width Container
    #max-width-container {
      max-width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    #max-width-container {
      max-width: 800px;
    }

    // Cards
    div div div {
      max-width: 250px;
    }
  }

  // 1024px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
    // Max Width Container
    #max-width-container {
      max-width: 600px;
    }

    // Cards
    div div div {
      width: 45%;
      max-width: none;
    }

    // Cards Container
    div div div div {
      margin: 0px 20px 60px 20px;
    }
  }

  // 768px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet768}) {
    // Max Width Container
    #max-width-container {
      max-width: 540px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    #max-width-container {
      max-width: 400px;
    }

    // Title
    h3 {
      text-align: center;
    }

    // Cards
    div div div {
      width: 100%;
      min-height: 100px;
    }

    // Cards Text
    div div div p {
      width: 90%;
      max-width: none;
    }
  }

  // 450px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile450}) {
    // Max Width Container
    #max-width-container {
      max-width: 88%;
    }
  }
`;

export default Container;
