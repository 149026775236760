export const SERVICIOS = [
    {
        imagen: "images/servicios/capacitacion.svg",
        alt: "Mujer sentada sobre libros leyendo en su computadora",
        titulo: "Capacitación",
        descripcion: "Capacitamos al Productor Asesor de Seguros para facilitar la gestión de ventas, Incluso capacitando en las herramientas digitales para vender y en técnicas de venta y objeciones."
    },
    {
        imagen: "images/servicios/informacion.svg",
        alt: "Pantalla de chat",
        titulo: "Información y actualización",
        descripcion: "Gestión y Servicios le acerca las últimas novedades e información actualizada en materia de seguros, haciendo que el Productor Asesor de Seguros asesore y brinde el mejor servicio a tus clientes."
    },
    {
        imagen: "images/servicios/cotizacion.svg",
        alt: "Gráficos de cotización",
        titulo: "Alternativas de cotización",
        descripcion: "El Productor Asesor de Seguros recibirá alternativas de diversas Compañías de primer nivel, de acuerdo con las necesidades de sus clientes y al los propios requerimientos de los mismos. Todo en solo lugar."
    },
    {
        imagen: "images/servicios/condiciones.svg",
        alt: "Persona señalando un gráfico",
        titulo: "Condiciones competitivas",
        descripcion: "El Productor Asesor de Seguros tendrá acceso a condiciones comerciales altamente competitivas, dependiendo de la aseguradora, con satisfactorios niveles comisionarios."
    },
    {
        imagen: "images/servicios/apoyo.svg",
        alt: "Dos personas charlando",
        titulo: "Asesoramiento y soporte",
        descripcion: "Contamos con áreas definidas que trabajan en conjunto con ejecutivos especializados que le brindarán los asesoramientos técnicos y soporte en tus prácticas comerciales."
    },
    {
        imagen: "images/servicios/emprendimientos.svg",
        alt: "Persona apuntando a uno de los emprendimientos sobre una pantalla",
        titulo: "Emprendimientos",
        descripcion: "Se trata de proyectos especiales que le brindarán al Productor Asesor de Seguros la posibilidad de desarrollar aquellos segmentos de mercado en loa cuales desee expandirse."
    },
    {
        imagen: "images/servicios/negociacion.svg",
        alt: "Maletín junto a un documento de texto",
        titulo: "Servicio de negociación",
        descripcion: "Para la suscripción de riesgos contamos con más de 50 años de preparación y trayectoria. Esto permite establecer diálogos permanentes con las Compañías con el objeto de obtener mayores posibilidades que el Productor Asesor de Seguros pueda ganar el negocio en cuestión."
    },
    {
        imagen: "images/ilustraciones/cards/poder.svg",
        alt: "Maletín junto a un documento de texto",
        titulo: "Optimizar tu tarea",
        descripcion: "Los ejecutivos comerciales de Gestión y Servicios colaboran y asisten de manera permanente al Productor Asesor de Seguros con el objeto pueda ser más eficiente en tu tarea como profesional del seguro."
    },
    {
        imagen: "images/ilustraciones/cards/profesionalizacion.svg",
        alt: "Maletín junto a un documento de texto",
        titulo: "Capacitación para venta digital",
        descripcion: "Recibirás capacitación y soporte en lo concerniente a como vender por medios digitales"
    },
    {
        imagen: "images/ilustraciones/cards/tecnicas.svg",
        alt: "Maletín junto a un documento de texto",
        titulo: "Soporte y asesoramiento para diseño de redes y web orientadas a la venta",
        descripcion: "Te asesoraremos y sugeriremos ideas para que el desarrollos de  tus redes sociales te den un muy buen resultado. Asi tamabién en el diseño de tu sitio web para que sea atractivo y eficiente"
    },
]